import React from 'react';
import { ResendVerifyButton } from '../../components/session'

class PleaseVerify extends React.Component {

  render() {
    return (
      <div className="LoginPage grid-row grid-column v-align h-align full-height">
        <div className="form form-page">
          <div className="f2 f-subdued s4">
            <span className="sr2 pt-icon-standard pt-icon-envelope" />
            Account created!
          </div>
          <div className="pt-callout pt-intent-success s4">
            Please check your inbox for an account verification email...
          </div>
          <div className="s3">
            <ResendVerifyButton text="Resend Email" />
          </div>
        </div>
      </div>
    );
  }



}

export default PleaseVerify;